/** @deprecated this is moved to libs */
export const phonenumberConfig = {
    // Netherlands (NL)
    // http://en.wikipedia.org/wiki/%2B31// http://wetten.overheid.nl/BWBR0010198phonenumberConfig: {
    countryCode: '31',
    internationalPrefix: '00',
    nationalPrefix: '0',
    nationalNumberPattern: '(?:[124-7]\\d\\d|3(?:[02-9]\\d|1[0-8]))\\d{6}|[89]\\d{6,9}|1\\d{4,5}',
    formats: [
        {
            description: 'Fixed line 3 digit area codes',
            pattern: '(\\d{3})(\\d{2})(\\d{2})(\\d{2})',
            format: '$1 - $2 $3 $4',
            testPattern: ['1[16-8]|2[259]|3[124]|4[17-9]|5[124679]'],
        },
        {
            description: 'Fixed line 2 digit area codes',
            pattern: '(\\d{2})(\\d{3})(\\d{2})(\\d{2})',
            format: '$1 - $2 $3 $4',
            testPattern: ['[1-57-9]'],
        },
        {
            description: 'Mobile',
            pattern: '(\\d)(\\d{2})(\\d{2})(\\d{2})(\\d{2})',
            format: '$1 - $2 $3 $4 $5',
            testPattern: ['6[1-58]'],
        },
    ],
    invalidNumbers: ['[8|9]00'],
};
