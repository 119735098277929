/** @deprecated this is moved to libs */
export const pikadayConfig = {
    format: 'DD-MM-YYYY',
    placeholder: 'dd-mm-jjjj',
    placeholderSelect: 'Selecteer datum',
    i18n: {
        previousMonth: 'Vorige maand',
        nextMonth: 'Volgende maand',
        months: [
            'januari',
            'februari',
            'maart',
            'april',
            'mei',
            'juni',
            'juli',
            'augustus',
            'september',
            'oktober',
            'november',
            'december',
        ],
        weekdays: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
        weekdaysShort: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
    },
};
